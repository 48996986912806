import React, { Component } from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import Layout from '../layout/layout'
import Recent from '../components/recent/index'
import './post.scss'
import './content-classes/wp-classes.scss'
import Seo from '../components/seo/'

class Post extends Component {
  render() {
    const post = this.props.data.wordpressPost

    return (
      <>
        <Layout>
        <Seo postTitle={post.title}/>
          <div className="post_content">
            <h1>{post.title}</h1>
            <div className="wordpress_content" dangerouslySetInnerHTML={{ __html: post.content }} />
          </div>
        <Recent/>
      </Layout>
      </>
    )
  }
}

Post.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export default Post

export const postQuery = graphql`
query ($id: String!) {
  wordpressPost(id: {eq: $id}) {
    title
    content
    modified
    author {
      node {
        name
      }
    }
  }
  site {
    siteMetadata {
      title
      description
    }
  }
}

`
